html{
  scroll-snap-type: y mandatory;
  overflow-y:scroll;
}
body{
  margin: 0px;
  cursor: none;
  height: 100vh;
    background-color: black;
    a{
      text-decoration: none;
      color: white;
      cursor: none;
    }
  
}

@keyframes change {
  0%{
    opacity: 0.8;
  }
  100%{
    opacity: 0.25;
  }
  
}


.child {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0% 10%;
  scroll-snap-align: start;
}

@font-face {
  font-family: Pixel;
  src: url("./assets/VCROSDMono.woff2");
}

.navbar{
  font-family: Pixel;
  position: fixed;
  width: 100%;
  z-index: 50;
  top: 0;
  padding-top: 20px;
  background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.01) 100%);

  ul{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 5px;
    
    i{
      display: none;
      margin: 0px;
    }

    a{
      color: rgb(184, 184, 184);
      filter: contrast(500%);
      text-shadow: 1px 1px 15px rgba(255, 255, 255, 0.918);
      transition: all 100ms ease-in-out;
      margin: 0px;
    }
  

    p{
      margin: 0px;
    }

    a::after{
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -5px;
      left: 0;
      background-color: blueviolet;
      transform-origin: bottom right;
      transition: transform 550ms ease-in-out;
    }

    a:hover::after{
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    
  }
  

}



.gliched-text{
    color: rgb(219, 216, 216);
    font-family: Pixel,monospace;
    line-height: 2em;
    font-size: 2.15rem;
    text-transform: uppercase;

    transition: all 500ms ease-in-out;

    text-shadow:0.1em 0 0 rgba(255, 0, 0, 0.349),
    0.1em 0.07em 0 rgba(0, 128, 0, 0.349),
    -0.1em -0.07em 0 rgba(0, 0, 255, 0.349);
}

.gliched-move{
    z-index: 5;
    position: relative;
    color: rgb(255, 255, 255);

    span{
      position: absolute;
      left: 0;
      z-index:1;
    }

    span:first-child{
      position: inherit;
      animation: move 500ms infinite;
    }

    span:nth-child(2){
        animation: move 600ms infinite;
        clip-path: polygon(0 20%, 100% 0, 100% 45%, 0 45%);
        transform: translate(-0.1em, -0.025em);
    }

    span:last-child{
        animation: move 300ms infinite;
        clip-path: polygon(0 55%, 100% 45%, 100% 100%, 0 100%);
        transform: translate(0.1em, 0.025em);
    }
}


.gliched-move:hover{
  
  span:first-child{
    position: inherit;
    animation: move 200ms infinite;
  }

  span:nth-child(2){
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }

  span:last-child{
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  
}

@keyframes move{

    0% {
        text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.505),
          -0.05em -0.025em 0 rgba(0, 255, 0, 0.505),
          -0.025em 0.05em 0 rgba(0, 0, 255, 0.505);
      }
      14% {
        text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.505),
          -0.05em -0.025em 0 rgba(0, 255, 0, 0.505),
          -0.025em 0.05em 0 rgba(0, 0, 255, 0.505);
      }
      15% {
        text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.505),
          0.025em 0.025em 0 rgba(0, 255, 0, 0.505),
          -0.05em -0.05em 0 rgba(0, 0, 255, 0.505);
      }
      49% {
        text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.505),
          0.025em 0.025em 0 rgba(0, 255, 0, 0.505),
          -0.05em -0.05em 0 rgba(0, 0, 255, 0.505);
      }
      50% {
        text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.505),
          0.05em 0 0 rgba(0, 255, 0, 0.505), 0 -0.05em 0 rgba(0, 0, 255, 0.505);
      }
      99% {
        text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.505),
          0.05em 0 0 rgba(0, 255, 0, 0.505), 0 -0.05em 0 rgba(0, 0, 255, 0.505);
      }
      100% {
        text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.505),
          -0.025em -0.025em 0 rgba(0, 255, 0, 0.505),
          -0.025em -0.05em 0 rgba(0, 0, 255, 0.505);
      }
}



.skill-container{
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(78, 77, 77, 0.329);
  flex: 7;
}



.c{
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 5px 0px 0px 5px ;
  background-color: blueviolet;
  box-shadow: 0px 0px 10px 3px blueviolet;
}

body::-webkit-scrollbar {
  width: 1px;
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgba(201, 10, 207, 0.3);
  outline: 1px solid blueviolet;
}

#cursor{
  transform: translate(-50%,-50%);
  height: 20px;
  width: 20px;
  transition: all 20ms ease;
  position: fixed;
  border: 2px solid;
  border-color: white;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0px 0px 25px 0px white,
  inset 0px 0px 5px 0px white;
  z-index: 100;
  transition: transform 250ms ease-in-out;
  animation: beat 1s infinite ease-in-out;
}

@keyframes beat {
  0%{
    box-shadow: 0px 0px 25px 0px white,
  inset 0px 0px 5px 0px white;
  }
  50%{
    box-shadow: 0px 0px 10px 0px white,
  inset 0px 0px 1px 0px white;
  }
  100%{
    box-shadow: 0px 0px 25px 0px white,
  inset 0px 0px 5px 0px white;
  }
}

#overlay-back{
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: pointer;
  opacity: 0.25;
  pointer-events: none;
}

#bg3{
  position: absolute;
  top: 0;
  z-index: -500;
  display: none;
  height: 100%;
  width: 100%;
}

#overlay-front::after{
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 3;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

#overlay-bottom{
  position: fixed;
  width: 100%;
  z-index: 50;
  bottom: 0;
  padding-top: 90px;
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.01) 100%);
  pointer-events: none;
}



#overlay-background{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: pointer;
  opacity: 1;
  filter: blur(5px);
  pointer-events: none;
}

.icon-hero{
  height: auto;
  width: 3em;
  align-self: center;
  justify-self: center;
  animation: icon-flick 1.5s infinite; 
}

.pulse{
  animation: pulse-flick 1500ms infinite;
}

@keyframes pulse-flick {
  0%{
    filter: drop-shadow(0px 0px 0px #ffffff)  blur(2px)  ;
  }

  33%{
    filter: drop-shadow(0px 0px 45px #Ffffff) blur(0px);
  }

  100%{
    filter: drop-shadow(0px 0px 0px #Ffffff) blur(2px);
  }
}

@keyframes icon-flick {
  0%{
    filter: drop-shadow(0px 0px 0px #ffffff)  blur(2px)  ;
  }
  100%{
    filter: drop-shadow(0px 0px 45px #Ffffff) blur(0px);
  }
}

@keyframes scroll {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


input{
  cursor: none;
  background-color: rgba(0, 0, 0, 0.322);
  color: white;
  margin: 2% 0%;
  padding: 12px;
  outline: none;
  border-radius: 5px;
  font-size: 1em;
  border: none;
}
input:focus{
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.746);
}
input::placeholder{
  color: #ffffff;
  opacity: 0.8;
}

textarea{
  margin: 2% 0%;
  cursor: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.382);
  color: white;
  border: none;
  resize: none;
  font-size: 1.5em;
}

textarea:focus{
  background-color: rgba(0, 0, 0, 0.746);
}

textarea::placeholder{
  color: #ffffff;
  opacity: 0.8;
}

.submit{
  margin: 2% 0;
  padding:2% 5% ;
  outline: none;
  border-radius: 15px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.644);
  font-size: 1.25em;
  font-weight: bolder;
  text-transform: uppercase;
  border: none;
  z-index: 10;
  cursor: none;
  transition: all 500ms ease-in-out;
  font-family: Pixel,monospace;
}

.submit:hover{
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 0px 0px 5px white;
  box-shadow: 0px 0px 10px 0px white,
  inset 0px 0px 10px 0px;
  transform: scale(1.25);
}

.social-items{
  font-size: 1.2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5em;
  column-gap: 5em;
  text-align: center;
}

i{
  margin-right: 15px;
}

.social-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.social-item:hover{
  z-index: 10;
  color: #ffffff;
  text-shadow:0px 0px 20px #d8d8d8;
}

.container{
  display:grid; 
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
}

@keyframes alert-ye {
  0%{
    box-shadow: 0px 0px 10px 5px rgb(189, 187, 187);
    opacity: 0;
  }
  50%{
    opacity: 0.8;
  }
  100%{
    box-shadow: 0px 15px 35px -10px rgb(189, 187, 187);
  }
}

.alert {
  opacity: 0;
  position: absolute;
  top: -3em;
  border-radius: 5px;
  box-shadow: 0px 15px 35px -10px rgb(189, 187, 187);
  width: 100%;
  text-align: center;
  z-index: 50;
  padding: 10px;
  background-color: #00000094;
  color: rgba(255, 255, 255, 0.767);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  transition: all 100ms ease-in;
}



.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: none;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.proj-element{
  display: grid;
  grid-template-columns: 10fr 5fr 10fr;
  column-gap: 5em;
  padding: 0% 5%;
  .gliched-move a{
    font-size: 3em;
    text-transform: none;
  }
  .gliched-move{
    margin: 15% 0;
    text-align: center;
  }
  .info{
    font-size: 1.5em;
  }
  .lang{
    word-spacing: 15em;
  }
}




$base: 0.6rem;

.scroll {
 position: absolute;
 bottom: 10em;
 display: flex;
 justify-content: center;
 align-items: center;
}

.chevron {
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
 content: '';
 position: absolute;
 top: 0;
 height: 100%;
 width: 50%;
 background: #c1c1c2;
 box-shadow: 0px 0px 15px 1px white;
}

.chevron:before {
 left: 0;
 transform: skewY(30deg);
}

.chevron:after {
 right: 0;
 width: 50%;
 transform: skewY(-30deg);
}

@keyframes move-chevron {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateY($base * 3.8);
 }
 66.6% {
  opacity: 1;
  transform: translateY($base * 5.2);
 }
 100% {
  opacity: 0;
  transform: translateY($base * 8) scale(0.5);
 }
}

@keyframes timeline {
0%{
  box-shadow: inset 0px 0px 0px 0px white;
  opacity: 0;
}
50%{
  box-shadow: inset 0px 0px 15px 0px white;
  opacity: 1;
}
100%{
  box-shadow: inset 0px 0px 0px 0px white;
  opacity: 0;
}

}


.time-point{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px white;
  filter: blur(1px);

  .line{
    position: relative;
    border-left: 5px solid rgba(255, 255, 255, 0.512);
    box-shadow: 0px 0px 70px -0px white;
    filter: blur(3px);
    height: 80vh;
    border-radius: 15px 15px 15px 15px;
  }

  .line-bottom{
    position: relative;
    align-self: flex-end;
    border-left: 5px solid rgba(255, 255, 255, 0.512);
    box-shadow: 0px 0px 70px 0px white;
    filter: blur(3px);
    height: 40vh;
  }

  .line-top{
    position: relative;
    align-self: flex-start;
    border-left: 5px solid rgba(255, 255, 255, 0.512);
    filter: blur(3px);
    box-shadow: 0px 0px 70px 0px white;
    height: 50vh;
  }

}

.mid-point{
  background-color: black;
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: inset 0px 0px 8px 0px white;
  animation: timeline 1s infinite;
}



.line-point{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px white;
  filter: blur(1px);

  .line-bottom{
    position: absolute;
    align-self: flex-end;
    border-left: 5px solid rgba(255, 255, 255, 0.512);
    box-shadow: 0px 0px 70px 0px white;
    filter: blur(3px);
    height: 30vh;
  }

  .line-top{
    position: relative;
    align-self: flex-start;
    border-left: 5px solid rgba(255, 255, 255, 0.512);
    filter: blur(3px);
    box-shadow: 0px 0px 70px 0px white;
    height: 35vh;
  }

}

#contact{
  .p{
    font-size: 1.5rem;
  }
}

.exp-element{
  display: grid;
  grid-template-columns: 2.5fr 1fr 10fr 10fr;
  column-gap: 3em;
  padding: 0% 5%;

  .linkfade:hover{
      text-shadow: 1px 1px 20px whitesmoke
    }

  

  .gliched-move{
    font-size: 2em;
  }
  .info{
    font-size: 1.5em;
  }
}


.skill-sec{
  display: flex;
  flex-direction: column;
}

#pagination {
  z-index: 10;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  list-style: none;

li{
  position: relative;
  margin: 20px 0;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  transition: all .2s ease;
  filter: blur(1px);
  
  &:hover {
    transform: scale(1.5);
  }
}

a {
  position: absolute;
  text-decoration: none;  
  left: 0;
  top: 0;
  color: inherit;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px white;
}
}

#main{
    @for $i from 1 to 11 {
    &.page-#{$i} {
      #pagination li:nth-of-type(#{$i}) {
        @extend %active-dot;
      }
    }
  }
}

%active-dot {
  transform: scale(2);
  box-shadow: 0px 0px 15px 1px white;
  filter: blur(0.25px);
}


@media only screen and (max-height: 600px), only screen and (max-width: 320px) {
  .gliched-text, .gliched-move, .navbar {
    font-size: 0.5em;
  }

  .child {
    height: 85vh;
  }

  .exp-element, .container, .social-items {
    grid-template-columns: 1fr;
    row-gap: 1.5em;
  }

  .proj-element {
    grid-template-columns: 1fr;
  }

  .gliched-text p {
    word-spacing: 5px;
  }

  #pagination {
    display: none;
  }
}

@media only screen and (max-width: 1880px) {
  .gliched-text {
    font-size: 1em;
  }

  .gliched-move, .proj-element .gliched-move, .exp-element .gliched-move {
    font-size: 0.75em;
  }

  .proj-element, .exp-element {
    justify-items: center;
    align-content: center;
    text-align: center;
  }
}

@media only screen and (max-width: 1300px) {
  .gliched-text {
    font-size: 1.7em;
  }

  .gliched-move {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1150px) {
  .exp-element {
    .time-point {
      display: none;
    }
    grid-template-columns: 1fr 5fr 5fr;
  }
}

@media only screen and (max-width: 1000px), only screen and (max-height: 900px) {
  .gliched-text, .gliched-move {
    font-size: 1em;
  }

  .navbar {
    font-size: 0.8em;
  }

  .child {
    height: 90vh;
  }

  #pagination {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .exp-element {
    .info {
      display: none;
    }
    grid-template-columns: 1fr 10fr 5fr;
  }

  .proj-element {
    .gliched-text p {
      word-spacing: normal;
    }

    .lang {
      margin: 1%;
      font-size: 1em;
      word-spacing: 10px;
    }

    .info {
      font-size: 1em;
    }

    .gliched-move {
      margin: 0;
    }

    .line-point {
      display: none;
    }

    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}

@media only screen and (max-width: 850px) {
  .gliched-text {
    font-size: 1.5em;
  }

  .gliched-move {
    font-size: 1em;
  }

  .social-items {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .exp-element {
    grid-template-columns: 1fr;
    row-gap: 2em;
  }
}

@media only screen and (max-width: 680px) {
  .gliched-text {
    font-size: 1.3em;
  }

  .gliched-move {
    font-size: 1em;
  }

  .navbar a::after {
    display: none;
  }

  .navbar ul p {
    display: none;
  }

  .navbar ul i {
    display: block;
  }

  .navbar a {
    text-shadow: none;
  }

  #cursor {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .gliched-text, .gliched-move {
    font-size: 1em;
  }

  .container {
    row-gap: 2em;
    grid-template-columns: 1fr;
  }

  #contact {
    justify-content: flex-end;
    .p {
      font-size: 0.55rem;
    }
  }

  .abt {
    padding-top: 50px;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 400px) {
  .gliched-text {
    font-size: 0.75em;
  }

  .gliched-move {
    font-size: 1em;
  }
}
